.dashboard_item {    
    width: 150px;
    height: 120px;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    font-family: 'Gloock', sans-serif;
    font-size: 20px;
    padding-top: 20px;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 10px;
    transition: 0.5s all ease-in-out;
    text-decoration: none;
}
.dashboard_item:hover{   
    transform: scale(1.1, 1.1);
}
.dashboard_item .icon {
    font-size: 35px;
}