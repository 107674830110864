.fixed-header {
    position: fixed;
    z-index: 1;
    top: 0;
    width: 100vw;
    height: 58px;
    background-color: #e7e7e7;
    display: flex;
    justify-content: space-between;
    color: black;
  }
  