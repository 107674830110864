.status-block{
    display: inline-block;
    text-align: center;
    padding: 2px;
    border: 1px solid black;
    width: 25px;
    height: 25px;
    font-size: 12px;
}
.not-answered-block{
    background-color: #cc3910;
    color: #FFF;
}
.answered-block{
    background-color: #03a848;
    color: #FFF;
}
.marked-review-block{
    background-color: #301375;
    color: #FFF;
}
.answered-marked-review-block{
    background-color: #301375;
    color: #FFF;
    position: relative;
}
.answered-marked-review-block .top-bar{
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    width: 100%;
    background-color: #03a848;
}
.questionlist-block {
    max-height: 250px;
    overflow: auto;
}
.questionlist-block .status-block{
    margin: 2px;
}
.section-select-chip {
    cursor: pointer;
    margin: 10px;
    margin-left: 5px;
    margin-right: 5px;    
}
.section-select-chip:hover {
    background-color: #d1d1d1;
}
.section-select-chip.active{
    background-color: #265491;
    color: #FFF;
}

.mobile-bottom-buttons {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    z-index: 1;
    background: #e7e7e7;
    padding: 15px;
    width: 100%;
    display: none;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
}

.mobile-bottom-buttons button {
    background: #265491;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 10px 22px;
    width: 100%;
    transition: 0.4s;
}

.mobile-bottom-buttons button:hover {
    background: #1464cc;
}

.mobile-bottom-buttons button svg {
    margin-right: 10px;
}

.mobile-bottom-buttons button:disabled {
    background: #45638a;
    color: #969595;
}

/* Bottom Buttons */
.mobile-bottom-buttons .bottom-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.mobile-bottom-buttons .bottom-buttons button:first-child {
    width: 100px;
    margin-right: 2px;
    border-radius: 0 0 0 10px;
}

.mobile-bottom-buttons .bottom-buttons button:first-child svg{
    margin-left: 20px;
}

.mobile-bottom-buttons .bottom-buttons button:last-child {
    width: 100px;
    margin-left: 2px;
    border-radius: 0 0 10px 0;
}

.mobile-bottom-buttons .bottom-buttons button:last-child svg{
    margin-left: 10px;
}

/* Top Buttons */
.mobile-bottom-buttons .top-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.mobile-bottom-buttons .top-buttons button:first-child {
    margin-right: 2px;
    border-radius: 10px 0 0 0;
}

.mobile-bottom-buttons .top-buttons button:last-child {
    border-radius: 0 10px 0 0;
}

/* Middle Buttons */
.mobile-bottom-buttons .middle-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 2px 0;
}


@media (max-width: 767px) {
    .mobile-bottom-buttons {
        display: unset;
    }
}