.dmenu-item { 
    width: 195px;
    padding: 30px;
    margin-bottom: 50px;
    cursor: pointer;    
    border-radius: 5px;    
    min-height: 70px;
    text-decoration: none;
}

.dmenu-item:hover { 
    box-shadow: 1px 1px 3px 1px #e2a4a4;
}
.dmenu-item_icon {
    margin-top: 5px;
    color: #ffffff;
    font-size: 28px;
}
.dmenu-item-text {
    font-weight: bold;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0.8px;
}