.curatoritem-container {
    border: 1px solid #ebebeb;
    padding: 15px;
}
.curatoritem-menu {
    padding: 10px;
    border: 1px solid #b0b0b0;
    border-radius: 5px;
    color: #808080;
    font-size: 18px;
    cursor: pointer;    
    display: inline-block;      
    margin-right: 15px;  
}
.curatoritem-menu-c{
    display: flex;
    align-items: center;
}
.curatoritem-menu-txt{
    margin-right: 5px;
    font-size: 22px;
}
.curatoritem-menu:hover {
    border: 1px solid #b02020;
    color: #b02020;
}
.curatoritem-listcontainer {
    border: 1px solid #ebebeb;
    padding: 15px;
    margin-top: 10px;
}
.cate-breadcrumb-link {
    cursor: pointer;
    text-decoration: none;
    color: #696969;
}
.cate-breadcrumb-link:hover{
    color: #403e3e;
}
.cate-breadcrumb-text {
    color: #a3a3a3;
}