.course-navigationbtn {
    font-size: 17px;
    color: #071936;
    cursor: pointer;
    background-color: #FCF6F5;
    margin-bottom: 10px;
    padding: 3px;
    padding-left: 10px;
    border-radius: 4px;
}
.course-navigationbtn:hover {
    background-color: #071936;
    color: #FCF6F5;
}
.course-navigationbtn.active {    
    color: #FCF6F5;
    background-color: #071936;
}
.courseitem-content-iframe {
    width: 100%;
    min-height: 60vh;
    margin-top: 5px;
}