.image-container { 
    border: 5px solid #d8dde6;
    text-align: center;
}
.image-item { 
    width: 140px;
    height: 140px;
    object-fit: cover;
}

.pdf-container { 
    border: 1px solid #d8dde6;    
    text-align: center;
}

.pdf-item { 
    width: 250px;    
}

.video-container { 
    border: 1px solid #d8dde6;    
    text-align: center;
}

.video-item { 
    width: 350px;    
}