.grid-container {
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(3, 1fr); /* Ensures equal columns */
  justify-content: stretch; /* Makes columns stretch evenly */
  align-items: start; /* Align items at the top */
  justify-items: center;
}
.grid-container>* {
  /* cursor: pointer;
  transition: .4s; */
}

.grid-container>*:hover {
  /* box-shadow: 1px 2px 0px 0px rgba(0, 0, 0, 0.2), -2px 0px 1px 0px rgba(0, 0, 0, 0.14), -5px -1px 3px 0px rgb(211 211 211);
   */
  /* background-color: antiquewhite; */
}

.main-container {
  margin-top: 35px;
  margin-bottom: 35px;
}



@media (max-width: 1120px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 650px) {
  .grid-container {
    grid-template-columns: unset;
    grid-gap: 15px;
  }
}