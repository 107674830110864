.title-container {
    max-width: 200px; /* Set the desired width to accommodate the truncated title */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.capitalize{
    text-transform: capitalize;
}
.overflow-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 15vw;
}
.image-handle img{
    max-height:20px;
}
