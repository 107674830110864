.test_component{
    margin: 10px;    
    min-height: 70px;
    padding: 10px;
    cursor: pointer;
    position: relative;
}
.test_component .top_design{
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 5px;
}