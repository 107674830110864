.grid-container {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(3, 1fr); /* Ensures equal columns */
    justify-content: stretch; /* Makes columns stretch evenly */
    align-items: start; /* Align items at the top */
    justify-items: center;
  }

  @media (max-width: 1120px) {
    .grid-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 650px) {
    .grid-container {
      grid-template-columns: unset;
      grid-gap: 15px;
      width: 100%;
    }
  }
  