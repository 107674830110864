.block {
    border-radius: 7px;
    background: #e7e7e7;
    color: #363636;
    text-decoration: none;
    transition: .4s;
    width: 380px;

    display: flex;
    flex-direction: column;
}

.block:hover {
    background: #c5c5c5;
}

.text-block {
    padding: 15px;
}

.image-container {
    height: 260px;
    overflow: hidden;
    border-radius: 7px 7px 0px 0px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.image-container img{
    transition: .4s;
    width: 100%;
}

.block:hover .image-container img {
    filter: brightness(70%);
}

.text-block .text-main {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-block .text-description {
    font-weight: 12px;
}



@media (max-width: 1270px) {
    .block {
        width: 300px;
    }
    
}

@media (max-width: 1120px) {
    .block {
        width: 450px;
    }
    
}

@media (max-width: 1006px) {
    .block {
        width: 400px;
    }
    
}

@media (max-width: 887px) {
    .block {
        width: 350px;
    }
    
}

@media (max-width: 800px) {
    .block {
        width: 300px;
    }
    
}

@media (max-width: 673px) {
    .block {
        width: 280px;
    }
    
} 

@media (max-width: 650px) {
    .block {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100px;
        overflow: hidden;
    }

    .image-container img{
        width: unset;
        height: 100%;
    }

    .image-container {
        height: 100px;
        width: 104px;
        border-radius: 7px 0px 0px 7px;
    }

    .text-block {
        width: calc(100% - 104px);
    }

    .text-description {
        display: -webkit-box;
        -webkit-line-clamp: 2; /* Number of lines */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .text-block .text-main  {
        margin-bottom: 4px;
    }
}