.item-container {
    /* min-height: 80vh; */
}
.drop-area-container {
    position: relative;
}

.avoid-clicks {
    pointer-events: none;
}

.drop-area {
    border: 3px dashed #c7e6ff;
    max-width: 550px;        
    margin: auto;
    margin-top: 55px;    
    margin-bottom: 55px;    
    background-color: #f7fcff;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

.drop-area.active {
    border: 3px dashed #63baff;
}
.drop-area * {
    pointer-events: none;
}

.drop-text-color { 
    color: #969696;
}

.drop-area-icon {    
    font-size: 65px;
    color: #b3b3b3;
}

.drop-selectfile-btn {
    pointer-events: all;
}