.category-container{
    background-color: #f2f2f3;
    padding: 10px;
    margin-right: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    border-left: 5px solid #0b3d80;
    min-height: 80px;
}
.category-container:hover{
    border-left: 5px solid #ff6207;
}
.btn-add-test{    
    height: 40px;
}
.modal-style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: #f2f2f3;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
}
.parent-modal-title{
    font-size: 16px;
    color: #3d3d3d;
}
.input-standard-basic{
    width: 100%;
}