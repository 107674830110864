.question-option {
  margin: 5px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 25px;
  border-left: 5px solid #fff;
  position: relative;
}
.question-option.correct {
  border-left: 5px solid green;
}
.question-option .qo-badge {
  position: absolute;
  right: 0px;
  top: -10px;
  background-color: green;
  color: #fff;
  padding: 1px 5px 1px 5px;
  border-radius: 4px;
  font-size: 12px;
}
.question-option .qo-badge.qo-correct-ans {
  background-color: green;
}
.question-option .qo-badge.inqo-correct-ans {
  background-color: red;
}

.question-option .qo-badge.qo-correct-ans-nc {
  background-color: blue;
}
@media (max-width: 600px) {
  .MuiTabs-flexContainer {
    flex-direction: column;
  }
}
.image img {
  max-width: 250px;
}
