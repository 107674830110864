.test-card {
    max-width: 500px;
    margin: auto;
    margin-top: 2px;
    background-color: #e1e1e1;
    padding: 15px;
    margin: 10px;
    border-radius: 7px;
    cursor: pointer;
    min-height: 150px;
}

.test-card .test-card-container {
    /* display: flex;
    flex-direction: row; */
}

.test-card-container .left-image {
    float: left;
}

.test-card-container .left-image svg{
    width: 24px;
    height: 100px;
    margin-left: -27px;
    /* margin-top: -53px; */
    transition: .4s;
    /* position: absolute; */
}

.test-card:hover .test-card-container .left-image svg {
    margin-top: -25px
}

.card-data .title{
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 3px;
    width: 100%;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}

.card-data:hover .title{
    /* background-color: #c4c1c1;
    transition: .4s;
    border-radius: 7px; */
}

.card-data .sub-title {
    display: flex;
    justify-content: space-between;
}

.card-data .sub-title span{
    font-weight: 600;
}

.show-more > div{
    display: flex;
    justify-content: space-between;
    background: #d7d6d6;
    padding: 3px 10px;
    margin: 5px 0px;
    border-radius: 7px;
}

.show-more > div > span{
    font-weight: 600;
    text-align: right;
}

.more-button {
    background: none;
    border: none;
    cursor: pointer;
    display: none;
}

@media (max-width: 1120px) {

    .test-card-container .left-image svg{
        width: 24px;
        height: 50px;
        margin-left: -27px;
        transition: .4s;
        margin-top: -3px;
    }

    .test-card:hover .test-card-container .left-image svg {
        margin-top: -3px;
    }
    

    .test-card {
        max-width: 100%;
        min-height: unset;
    }

    .show-more {
        display: none;
    }

    .show-more.show {
        display: unset;
    }

    .more-button {
        display: unset;
    }

  }
  