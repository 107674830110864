* {
    margin: 0;
    padding: 0;    
}

.top-container {
    background-color: #dbeaff;
    height: 100vh;
    position: relative;
}

.top-container .logo-area {
    margin-left: 15px;
    position: absolute;
    z-index: 100;
    left: 0px;
    top: 15px;
}

.top-container .logo-area img {
    width: 180px;
}

.top-container .menu-area {
    height: 65px;
    display: block;
    text-align: right;
}

.top-container .menu-area .menu-container {
    height: 88px;
    background-color: #faf8f7;
}

.top-container .menu-area .menu-container ul {
    display: inline-block;
    margin-top: 35px;
    margin-right: 25px;
}

.top-container .menu-area .menu-container li.menu-item {
    display: inline-block;    
    cursor: pointer;
    font-size: 18px;
    letter-spacing: 0.1px;
    color: #525252;
    padding: 5px 10px 5px 10px;
    border-radius: 3px;
    font-weight: 400;
    color: #202166;
}
.top-container .menu-area .menu-container li.menu-item:hover{
    background-color: #ededed;    
}

.main-container {
    margin-top: 35px;
    margin-bottom: 35px;
}

.show-on-mobile-view {
    display: none;
}

@media only screen and (max-width: 650px) {
    .main-container {
        margin-top: 15px;
        height: calc(100vh - 164px);
    }
}

@media only screen and (max-width: 600px) {
    .button-responsive-auto{
        width: 100%;
    }

    .hide-from-mobile-view {
        display: none;
    }

    .show-on-mobile-view {
        display: flex;
    }
}



.modal-design{
    min-width: 350px;
    min-height: 200px; 
}
.modal-design .modal-heading{
    text-align: center;
    color:#575656;
    font-weight: 600;
}
.modal-create-question-container .modal-design{
    width: 80vw;
}
.ckeditor-question .ck-editor__editable:not(.ck-editor__nested-editable) { 
    min-height: 150px;
}