.itemcontainer {    
    padding: 10px;
}

.courseitem {    
    height: 350px;
    background-color: rgb(250, 249, 249);    
    box-shadow: 1px 1px 3px 1px #c9c9c9;
    overflow: auto;
    position: relative;
    margin-bottom: 20px;
}

.courseitem:hover {
    box-shadow: 1px 1px 2px 1px #b5b5b5;
}

.courseitem-title {
    background-color: #3f4240;
    font-size: 18px;
    font-weight: 500;
    color: azure;
    padding: 4px;
    text-align: center;
    text-transform: capitalize;
}
.courseitem-content {
    padding: 5px;    
}

.courseitem-content-title {
    font-size: 15px;
}

.courseitem-content-image{
    width: 100%;
    max-height: 240px;
    margin-top: 5px;
    object-fit: cover;
}

.courseitem-actions {
    background-color: #fff;
    text-align: right;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0px;
}

.courseitem-content-video {
    width: 100%;
    max-height: 240px;
    margin-top: 5px;
}
.courseitem-content-iframe {
    width: 100%;
    margin-top: 5px;
    overflow: hidden;
}
.courseitem-content-text{
    min-height: 240px;
}